import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Header from '@sections/Header';
import CountryPortfolio from '@sections/CountryPortfolio';
import Faq from '@sections/Faq';
import Footer from '@sections/Footer';

import HighlightedText from '@components/common/Generic/HighlightedText';

const IndexPage = () => (
  <Layout>
    <Navbar />
    <HighlightedText />
    <Header />
    <CountryPortfolio />
    <Faq />
    <Footer />
  </Layout>
);

export default IndexPage;
