import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

// import usaimage from '../../images/logos/countries/usa.png';
// <img src={usaimage} alt="Logo" />

import Countries from '@components/common/Countries'

import { Section, Container } from '@components/global';

const Team = () => (
  <StaticQuery
    query={graphql`
      query {
        allFile(filter: { sourceInstanceName: { eq: "team" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        customers_pot: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "customers_pot" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="global accolades">
        <div style={{height: 60}}></div>
        <Container style={{ position: 'relative' }}>
          <h1>Our Global Accolades</h1>
          <br />
          <Countries />
          <Art>
            <Img fluid={data.customers_pot.childImageSharp.fluid} />
          </Art>
          <ArtMobile>
            <Img fluid={data.customers_pot.childImageSharp.fluid} />
          </ArtMobile>
        </Container>
      </Section>
    )}
  />
);

const Art = styled.figure`
  width: 800px;
  margin: -80px 0;
  position: absolute;
  top: 120px;
  left: 62%;

  @media (max-width: ${props => props.theme.screen.lg}) {
    top: 20%;
    display: none;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    display: none;
  }
`;

const ArtMobile = styled.figure`
  width: 100%;
  margin: 0;
  display: none;
  margin-top: 64px;
  margin-bottom: -60%;

  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    display: none;
  }
`;

const ClaimToFame = styled.p`
  ${props => props.theme.font_size.verysmall};
  color: ${props => props.theme.color.black.light};
`;

export default Team;
