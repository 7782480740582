import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Container } from '@components/global';
// import ExternalLink from '@common/ExternalLink';
import FaqItem from '@common/FaqItem';

// import StudentIcon from '@static/icons/stats/student.svg';
// import CountriesIcon from '@static/icons/stats/countries.svg';
// import CollegeIcon from '@static/icons/stats/college.svg';
// import PlacedIcon from '@static/icons/stats/placed.svg';
// import ScholarshipIcon from '@static/icons/stats/scholarship.svg';

import IECAIcon from '@static/icons/ieca.png';
import UCIIcon from '@static/icons/uci.png';
import YoutubeIcon from '@static/icons/youtube-colored.png';

const whyUs = [
  {
    title: () => <div><CircleBullet /> 100.00% Placements</div>,
    content: () => (
      <>
        Yes!! Correct to the second decimal point! We are proud of it and we work really hard to maintain it.
      </>
    ),
  },
  {
    title: () => <div><CircleBullet /> Stellar Scholarships and Financial Offers</div>,
    content: () => (
      <>
        Put together our Fall 2020 Applicants bagged scholarships worth INR 47,048,000.00 with an average offer of 2,240,380.00 per student! Isn’t that stupendous!
      </>
    ),
  },
  {
    title: () => <div><CircleBullet /> Limited Enrolments</div>,
    content: () => (
      <>
        We cap our enrolments to 40 per intake. This helps us ensure Quality &amp; Attention to Detail.
      </>
    ),
  },
  {
    title: () => <div><CircleBullet /> Personalized Application Themes</div>,
    content: () => (
      <>
        We don't do cliche. Every student is unique and so should be his application strategy.
      </>
    ),
  },
  {
    title: () => <div><CircleBullet /> Campus Specific Admission Insights</div>,
    content: () => (
      <>
        We exploit our global network to provide students with valuable campus specific data to enhance the factor of Demonstrated Interest in their profile.
      </>
    ),
  },
  {
    title: () => <div><CircleBullet /> Extensive Profile Building &amp; Editing Sessions</div>,
    content: () => (
      <>
        Our profile building sessions span across Academics, Extracurricular, and Social Media domains. Our established editors will assist you in weaving rich gripping content for essays, and statements.
      </>
    ),
  },
  {
    title: () => <div><CircleBullet /> Pre-Departure Orientation</div>,
    content: () => (
      <>
        We help you network with Campus Alumni and Admissions Officers for an insightful session on how to kickstart your Freshman year.
      </>
    ),
  },
];

const selectedSourceStyle = {
  boxShadow: '0 2px 10px 0 rgba(0,0,0,.2)',
  borderRadius: 8
}

const FloaterDivComponent = () => {
  const sourceDataMap = {
    uci: {
      position: 0,
      name: "University of California - Irvine",
      description: () => <div>
        Our Chief Consultant and Founder is a <b>University of California - Irvine’s Certified International Education Consultant (IEC)</b>. This IEC certification is recognised and endorsed by <b>United States' National Association of College Admission Counselling (NACAC)</b>. Certified IEC's travel across continents and countries mandatorily to gather first hand information on colleges and campuses.
      </div>
    },
    ieca: {
      position: 1,
      name: "IECA",
      description: () => <div>
        Similar to <b>NACAC</b>, <b>IECA</b> is a private organization for certified International Education Consultants. With it's activities in <b>30 countries</b>, <b>IECA</b> provides its members with a pool of authentic information for College Admission Consulting. <b>This group of 2000+ Global Admission Consultants adds to our International Network</b>.
      </div>
    },
    experience: {
      position: 2,
      name: "Years of Experience",
      description: () => <div>
        We derive our legacy from <b>The India Partners - Manya - The Princeton Review.</b> Our Founder & Chief Consultant has worked with them as Education Consultant for their <b>Exclusive and Ultra Premium Admission Packages</b>. Furthermore, she has been trained in Admissions by ex Admission Officer of <b>Harvard University</b>.
      </div>
    },
  };

  const [source, setSource] = useState('uci');
  const [isHovered, setHovered] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isHovered) {
        const keys = Object.keys(sourceDataMap);
        const nextPosition = sourceDataMap[source].position < 2 ? sourceDataMap[source].position + 1 : 0;
        setSource(keys[nextPosition]);
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [isHovered, source]);

  const onMouseEnter = (key) => {
    setSource(key)
    setHovered(true)
  }

  const onMouseLeave = (key) => {
    setHovered(false)
  }

  return (<FloaterDiv>
    <div style={{ width: '100%', textAlign: 'center', marginBottom: 50 }}>
      <h2>Sources of Our Confidence</h2>
      {/* <hr style={{ width: '35%' }} /> */}
    </div>
    <HeaderBannerDiv>
      <HeaderLogo
        onMouseEnter={() => onMouseEnter('uci')}
        onMouseLeave={() => onMouseLeave()}
        style={source === 'uci' ? selectedSourceStyle : {}}
      >
        <HeroIcon src={UCIIcon} alt="link" padding={10} />
      </HeaderLogo>
      <HeaderLogo
        onMouseEnter={() => onMouseEnter('ieca')}
        onMouseLeave={() => onMouseLeave()}
        style={source === 'ieca' ? selectedSourceStyle : {}}
      >
        <HeroIcon src={IECAIcon} alt="link" padding={10} maxWidth={230} />
      </HeaderLogo>
      <HeaderLogo
        onMouseEnter={() => onMouseEnter('experience')}
        onMouseLeave={() => onMouseLeave()}
        style={source === 'experience' ? selectedSourceStyle : {}}
      >
        <FormerConsultantText>
          <div style={{margin: '0 auto', textAlign: 'center', padding: '10px 0px'}}>
            <div style={{marginBottom: 7, fontSize: 14}}>
              <strong>Former Consultant @</strong>
            </div>
            <div style={{color: 'black', fontSize: 16, fontWeight: 'bold', marginBottom: 3, color: '#00206A'}}>
              India Partners - The Princeton Review
            </div>
          </div>
        </FormerConsultantText>
      </HeaderLogo>
    </HeaderBannerDiv>
    <hr />
    <div style={{ width: '78%', margin: '0 auto', color: '#564F62', transition: 'opacity 0.5s', opacity: 1 }}>
      <div style={{ flex: 1, width: '100%', marginBottom: 20, marginTop: 30, color: '#000' }}>
        <h3>{sourceDataMap[source].name}</h3>
      </div>
      <SourceDescriptionDiv>
        {sourceDataMap[source].description()}
      </SourceDescriptionDiv>
    </div>
  </FloaterDiv>)
}

const AccordianComponent = () => {
  const [openIndex, setOpenIndex] = useState(-1);

  const toggleOpenIndex = (index) => {
    const isOpen = openIndex === index;
    setOpenIndex(isOpen ? -1 : index);
  }

  return (<Text style={{ marginBottom: 80, fontSize: 12 }}>
    <div style={{ marginTop: 20 }}>
      {whyUs.map(({ title, content }, index) => (
        <div key={title} onClick={() => {
          toggleOpenIndex(index);
        }}>
          <FaqItem title={title()}  open={openIndex === index}>
            {content()}
          </FaqItem>
        </div>
      ))}
    </div>
  </Text>)
}

const YoutubeHeader = styled.h4`
  ${props => props.theme.font_size.verysmall};
  display: inline;
  color: ${props => props.theme.color.white.regular};
  opacity: 0.6;
`

const YoutubeSection = styled.div`
  color: ${props => props.theme.color.white.regular};
  text-align: center;
  border-radius: 18px;
  width: 40%;
  margin: 0 auto;
  padding: 10px 10px 13px 10px;
  background-color: rgba(0,0,0,0.7);
  cursor: pointer;
  box-shadow: 0 6px 26px 0 rgba(0,0,0,0.3);

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 90%;
  }
`

const YoutubeBody = styled.div`
  font-size: 16px;

  @media (max-width: ${props => props.theme.screen.md}) {
    font-size: 16px;
  }
`

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        art_build: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "build" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <HeaderWrapper>
          <Container>
            <HeadingText>
              A Premium International Education Consultancy
            </HeadingText>
            <HeadingText subHeading={true}>
              Up Your Admissions Game With Us!
            </HeadingText>
            <YoutubeSection onClick={() => window.open("https://www.youtube.com/watch?v=3QHjFdIVuko")}>
              <div>
                <img src={YoutubeIcon} alt="Youtube Icon" align="middle" style={{paddingBottom: 7, width: 32}} />
                <div style={{verticalAlign: 'middle', display: 'inline', padding: '10px 0px 10px 8px'}}>
                  <YoutubeHeader>Latest From Our YouTube Channel</YoutubeHeader>
                </div>
              </div>
              <div>
                  <YoutubeBody>Global COVID-19 Status and Suggestions for International Students</YoutubeBody>
              </div>
            </YoutubeSection>
            <Grid style={{ paddingTop: 10 }}>
              <div style={{ width: '100%' }}>
                <Art>
                  <Img fluid={data.art_build.childImageSharp.fluid} style={{ marginTop: -50 }} />
                </Art>
              </div>
              <Text id="hero-text">
                <Text>
                  {/* <h4>
                  This Helps Deliver
                </h4> */}
                  <h4 style={{ lineHeight: 1, marginBottom: 45, marginLeft: -40, fontSize: 24 }}>
                    What Distinguishes Us:
                  </h4>
                </Text>
                {AccordianComponent()}
              </Text>
            </Grid>
          </Container>
        </HeaderWrapper>
        {FloaterDivComponent()}
      </div>
    )}
  />
);

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.primary};
  color: ${props => props.theme.color.lighter};
  padding-top: 96px;
  padding-bottom: 120px;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding-top: 128px;
  }
`;

const Art = styled.figure`
  width: 100%;
  margin: 0;

  img {

  }

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      width: 80%;
      left: 10%;
    }
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    margin-top: 55px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3.5fr 6.5fr;
  align-items: center;
  grid-gap: 64px;

  > #hero-text {
    margin-left: 120px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 80px;

    > ${Art} {
      order: 2;
    }

    > #hero-text {
      margin-left: 0px;
      text-align: center;
    }
  }
`;

// const TextWrapper = styled.div`
//   justify-self: center;

//   @media (max-width: ${props => props.theme.screen.md}) {
//     justify-self: start;
//   }
// `;

const Text = styled.div`
  justify-self: center;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: start;
  }
`;

// const StyledExternalLink = styled(ExternalLink)`
//   color: inherit;
//   text-decoration: none;

//   &:hover {
//     color: ${props => props.theme.color.black.regular};
//   }
// `;

// const InfoIcon = styled.img`
//   width: 24px;
//   vertical-align: middle;
//   margin-right: 4px;
//   border: 1px solid;
//   border-radius: 12px;
// `;

const HeroIcon = styled.img`
  max-width: ${props => props.maxWidth || 200}px;
  padding: ${props => props.padding || 0}px;

  @media (max-width: ${props => props.theme.screen.md}) {
    max-width: 250px;
  }
`;

const HeadingText = styled.h2`
  line-height: 1.5;
  margin-top: ${props => props.subHeading ? '10px' : '40px'};
  margin-bottom: 20px;
  text-align: center;
  ${props => props.subHeading ? props.theme.font_size.large : props.theme.font_size.xlarge};

  @media (max-width: ${props => props.theme.screen.md}) {
    margin-top: -10px;
    ${props => props.subHeading ? props.theme.font_size.small : props.theme.font_size.large};
  }
`;

// const InfoIconContainer = styled.div`
//   margin-top: 10px;
//   ${props => props.theme.font_size.small};
// `;

// const InfoDescription = styled.div`
//   margin-top: 4px;
//   display: absolute;
//   margin-left: 32px;
//   ${props => props.theme.font_size.verysmall};
// `;

const FormerConsultantText = styled.div`
  color: ${props => props.theme.color.black.light};
  font-size: 12px;
`;

const CircleBullet = styled.span`
  height: 12px;
  width: 12px;
  margin-right: 4px;
  background-color: ${props => props.theme.color.black.light};
  border-radius: 50%;
  display: inline-block;
`;

const FloaterDiv = styled.div`
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: 20px 30px;
  margin: 0 auto;
  width: 90%;
  margin-top: -120px;
  box-shadow: 0 6px 26px 0 rgba(0,0,0,.2);

  @media (max-width: ${props => props.theme.screen.md}) {
    bottom: 40px;
  }  
`;

const HeaderLogo = styled.div`
  flex: 1;
  margin: 0 auto;
  align-self: center;
  cursor: pointer;

  // for displaying these as blocks
  margin-right: 30px;
  margin-bottom: 20px;
  padding: 5px;
  min-width: 250px;
  // border: 2px solid gray;

  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
    border-radius: 20px;
    margin: 5px;
  }
`;

const HeaderBannerDiv = styled.div`
  display: flex;
  text-align: center;
  align-content: center;
  margin: 0 auto;
  width: 80%;
  flex-direction: row;
  font-type: ${props => props.theme.font.primary};
  padding: 5px 5px;

  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
    background-color: transparent;
    border: 0px;
    width: 100%;
    margin-top: -35px;
    padding: 0px;
  }
`;

const SourceDescriptionDiv = styled.div`
  text-align: justify;
  margin-bottom: 20;
  line-height: 1.4;

  b {
    color: #000;
  }
`;

export default Header;