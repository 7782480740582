import React from 'react';

import { Section, Container } from '@components/global';

import FaqItem from '@common/FaqItem';
// import ExternalLink from '@common/ExternalLink';

const ADMISSION_FAQS = [
  {
    title: 'What clientele do we handle?',
    content: () => (
      <>
        We have expertise in placing Indian Undergrad and Grad school aspirants to the world’s finest universities.
      </>
    ),
  },
  {
    title: 'How early do we start working with students?',
    content: () => (
      <>
        For Undergraduate Aspirants: We start as early as Grade 10.<br />
        For Grad School Aspirants: We recommend starting in your penultimate college year.
      </>
    ),
  },
  {
    title: 'What services do we provide?',
    content: () => (
      <>
        We operate with students and families on per case basis and tailor make a plan unique to their choice of country, program, unique profile strengths, finances and needs.
      </>
    ),
  },
  {
    title: 'When should I start?',
    content: () => (
      <>
        The overseas admission process alone may take 4-8 months depending on your choice of destination. If you are looking at elite schools, it’s advisable that:
        <ul>
          <li>
            <b>UG Aspirants:</b> Should start as early as Grade 10
          </li>
          <li>
            <b>PG/MBA Aspirants:</b> Should start latest by the beginning of their final year.
          </li>
        </ul>


      </>
    ),
  },
  {
    title: 'Is the Admission Guaranteed?',
    content: () => (
      <>
        An honest consultant will never guarantee you an admission. We @ Admission Rush, operate by applying to a balanced mix of universities spread across 3 bands viz. High Aspiration, Reach, and Safety band. And we do have a 100.00% placement rate. So you can be rest assured with us.
      </>
    ),
  },
];

const Faq = () => (
  <Section id="faq" accent="secondary">
    <div style={{height: 60}}></div>
    <Container>
      <h1 style={{ marginBottom: 40 }}>Frequently Asked Questions</h1>
      <div>
        {ADMISSION_FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </Container>
  </Section>
);

export default Faq;
