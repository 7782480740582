import React, { useState } from 'react';
import styled from 'styled-components';

import CountrySelector from '@components/common/Generic/CountrySelector'

// USA
import babson_college_img from '@images/logos/colleges/usa/babson_college.jpeg'
import brown_university_img from '@images/logos/colleges/usa/brown_university.png'
import claremont_university_img from '@images/logos/colleges/usa/claremont_university.jpg'
import columbia_university_img from '@images/logos/colleges/usa/columbia_university.jpg'
import cornell_university_img from '@images/logos/colleges/usa/cornell_university.png'
import northwestern_university_img from '@images/logos/colleges/usa/northwestern_university.png'
import pratt_institute_of_design_img from '@images/logos/colleges/usa/pratt_institute_of_design.png'
import princeton_university_img from '@images/logos/colleges/usa/princeton_university.png'
import rice_university_img from '@images/logos/colleges/usa/rice_university.png'
import stanford_university_img from '@images/logos/colleges/usa/stanford_university.png'
import university_of_texas_austin_img from '@images/logos/colleges/usa/university_of_texas_austin.png'
import wharton_university_img from '@images/logos/colleges/usa/wharton_university.png'
import yale_university_img from '@images/logos/colleges/usa/yale_university.jpg'
import dartmouth_university_img from '@images/logos/colleges/usa/dartmouth_university.jpg'
import duke_university_img from '@images/logos/colleges/usa/duke_university.png'
import georgetown_university_img from '@images/logos/colleges/usa/georgetown_university.png'
import georgia_institute_of_technology_img from '@images/logos/colleges/usa/georgia_institute_of_technology.png'
import johns_hopkins_university_img from '@images/logos/colleges/usa/johns_hopkins_university.png'
import new_york_university_img from '@images/logos/colleges/usa/new_york_university.png'
import uc_berkeley_img from '@images/logos/colleges/usa/uc_berkeley.png'
import uiuc_img from '@images/logos/colleges/usa/uiuc.png'
import university_of_california_San_Diego_img from '@images/logos/colleges/usa/university_of_california_San_Diego.png'
import university_of_chicago_img from '@images/logos/colleges/usa/university_of_chicago.gif'
import university_of_michigan_ann_arbor_img from '@images/logos/colleges/usa/university_of_michigan_ann_arbor.png'
import university_of_southern_california_img from '@images/logos/colleges/usa/university_of_southern_california.png'

// Canada
import carleton_university_img from '@images/logos/colleges/canada/carleton_university.png'
import mcgill_university_img from '@images/logos/colleges/canada/mcgill_university.png'
import mcmaster_university_img from '@images/logos/colleges/canada/mcmaster_university.png'
import queens_university_img from '@images/logos/colleges/canada/queens_university.jpg'
import ryerson_university_img from '@images/logos/colleges/canada/ryerson_university.png'
import simon_fraser_university_img from '@images/logos/colleges/canada/simon_fraser_university.jpg'
import university_of_alberta_img from '@images/logos/colleges/canada/university_of_alberta.png'
import university_of_british_columbia_img from '@images/logos/colleges/canada/university_of_british_columbia.png'
import university_of_calagary_img from '@images/logos/colleges/canada/university_of_calagary.png'
import university_of_toronto_img from '@images/logos/colleges/canada/university_of_toronto.png'
import university_of_waterloo_img from '@images/logos/colleges/canada/university_of_waterloo.jpg'
import victoria_university_img from '@images/logos/colleges/canada/victoria_university.png'
import western_university_img from '@images/logos/colleges/canada/western_university.jpg'
import york_university_img from '@images/logos/colleges/canada/york_university.png'

// UK
import andrews_university_img from '@images/logos/colleges/uk/andrews_university.png'
import bristol_university_img from '@images/logos/colleges/uk/bristol_university.png'
import city_university_london_img from '@images/logos/colleges/uk/city_university_london.jpg'
import dublin_university_img from '@images/logos/colleges/uk/dublin_university.jpg'
import durham_university_img from '@images/logos/colleges/uk/durham_university.png'
import edinburgh_university_img from '@images/logos/colleges/uk/edinburgh_university.png'
import exeter_university_img from '@images/logos/colleges/uk/exeter_university.png'
import imperial_college_london_img from '@images/logos/colleges/uk/imperial_college_london.jpg'
import kings_college_london_img from '@images/logos/colleges/uk/kings_college_london.png'
import london_business_school_img from '@images/logos/colleges/uk/london_business_school.png'
import london_school_of_economics_img from '@images/logos/colleges/uk/london_school_of_economics.png'
import oxford_university_img from '@images/logos/colleges/uk/oxford_university.png'
import trinity_college_cambridge_img from '@images/logos/colleges/uk/trinity_college_cambridge.png'
import university_college_london_img from '@images/logos/colleges/uk/university_college_london.png'
import university_of_bath_img from '@images/logos/colleges/uk/university_of_bath.png'
import university_of_glasgow_img from '@images/logos/colleges/uk/university_of_glasgow.jpeg'
import university_of_manchester_img from '@images/logos/colleges/uk/university_of_manchester.png'
import warwick_university_img from '@images/logos/colleges/uk/warwick_university.jpg'

// Germany
import bard_college_berlin_img from '@images/logos/colleges/germany/bard_college_berlin.jpeg'
import esmt_img from '@images/logos/colleges/germany/esmt.png'
import mannheim_business_school_img from '@images/logos/colleges/germany/mannheim_business_school.png'
import rwth_img from '@images/logos/colleges/germany/rwth.png'
import tu_munich_img from '@images/logos/colleges/germany/tu_munich.png'

// France
import escp_img from '@images/logos/colleges/france/escp.png'
import essec_img from '@images/logos/colleges/france/essec.png'
import grenoble_img from '@images/logos/colleges/france/grenoble.png'
import hec_img from '@images/logos/colleges/france/hec.png'
import skema_business_school_img from '@images/logos/colleges/france/skema_business_school.png'

// Ireland
import dublin_city_university_img from '@images/logos/colleges/ireland/dublin_city_university.png'
import maynooth_university_img from '@images/logos/colleges/ireland/maynooth_university.jpeg'
import trinity_college_dublin_img from '@images/logos/colleges/ireland/trinity_college_dublin.jpeg'
import university_college_dublin_img from '@images/logos/colleges/ireland/university_college_dublin.jpeg'

// Australia
import australian_national_university_img from '@images/logos/colleges/australia/australian_national_university.png'
import royal_management_institute_of_technology_img from '@images/logos/colleges/australia/royal_management_institute_of_technology.png'
import university_of_adelaide_img from '@images/logos/colleges/australia/university_of_adelaide.png'
import university_of_melbourne_img from '@images/logos/colleges/australia/university_of_melbourne.png'
import university_of_new_south_wales_img from '@images/logos/colleges/australia/university_of_new_south_wales.png'
import university_of_queensland_img from '@images/logos/colleges/australia/university_of_queensland.png'
import university_of_sydney_img from '@images/logos/colleges/australia/university_of_sydney.png'

// New Zealand
import auckland_university_img from '@images/logos/colleges/newzealand/auckland_university.jpeg'
import canterbury_university_img from '@images/logos/colleges/newzealand/canterbury_university.jpeg'
import university_of_otago_img from '@images/logos/colleges/newzealand/university_of_otago.jpeg'
import wellington_university_img from '@images/logos/colleges/newzealand/wellington_university.jpeg'

// HKS
import hkust_img from '@images/logos/colleges/hks/hkust.jpeg'
import ntu_img from '@images/logos/colleges/hks/ntu.jpeg'
import nus_img from '@images/logos/colleges/hks/nus.png'
import smu_img from '@images/logos/colleges/hks/smu.png'

const US_UNIVS = [
  brown_university_img,
  claremont_university_img,
  babson_college_img,
  columbia_university_img,
  cornell_university_img,
  northwestern_university_img,
  pratt_institute_of_design_img,
  princeton_university_img,
  rice_university_img,
  stanford_university_img,
  university_of_texas_austin_img,
  wharton_university_img,
  yale_university_img,
  dartmouth_university_img,
  duke_university_img,
  georgetown_university_img,
  georgia_institute_of_technology_img,
  johns_hopkins_university_img,
  new_york_university_img,
  uc_berkeley_img,
  uiuc_img,
  university_of_california_San_Diego_img,
  university_of_chicago_img,
  university_of_michigan_ann_arbor_img,
  university_of_southern_california_img,
]

const CANADA_UNIVS = [
  carleton_university_img,
  mcgill_university_img,
  mcmaster_university_img,
  queens_university_img,
  ryerson_university_img,
  simon_fraser_university_img,
  university_of_alberta_img,
  university_of_british_columbia_img,
  university_of_calagary_img,
  university_of_toronto_img,
  university_of_waterloo_img,
  victoria_university_img,
  western_university_img,
  york_university_img,
]

const UK_UNIVS = [
  andrews_university_img,
  bristol_university_img,
  city_university_london_img,
  dublin_university_img,
  durham_university_img,
  edinburgh_university_img,
  exeter_university_img,
  imperial_college_london_img,
  kings_college_london_img,
  london_business_school_img,
  london_school_of_economics_img,
  oxford_university_img,
  trinity_college_cambridge_img,
  university_college_london_img,
  university_of_bath_img,
  university_of_glasgow_img,
  university_of_manchester_img,
  warwick_university_img,
]

const GERMAN_UNIVS = [
  bard_college_berlin_img,
  esmt_img,
  mannheim_business_school_img,
  rwth_img,
  tu_munich_img,
]

const FRANCE_UNIVS = [
  escp_img,
  essec_img,
  grenoble_img,
  hec_img,
  skema_business_school_img,
]

const IRELAND_UNIVS = [
  dublin_city_university_img,
  maynooth_university_img,
  trinity_college_dublin_img,
  university_college_dublin_img,
]

const AUSTRALIA_UNIVS = [
  australian_national_university_img,
  royal_management_institute_of_technology_img,
  university_of_adelaide_img,
  university_of_melbourne_img,
  university_of_new_south_wales_img,
  university_of_queensland_img,
  university_of_sydney_img,
]

const NEW_ZEALAND_UNIVS = [
  auckland_university_img,
  canterbury_university_img,
  university_of_otago_img,
  wellington_university_img,
]

const HKS_UNIVS = [
  hkust_img,
  ntu_img,
  nus_img,
  smu_img,
]

const COUNTRIES = [
  'USA',
  'Canada',
  'UK',
  'Germany',
  'France',
  'Ireland',
  'Australia',
  'New Zealand',
  'Hong Kong and Singapore',
];

const UNIV_MAP = {
  'USA': US_UNIVS,
  'Canada': CANADA_UNIVS,
  'UK': UK_UNIVS,
  'Germany': GERMAN_UNIVS,
  'France': FRANCE_UNIVS,
  'Ireland': IRELAND_UNIVS,
  'Australia': AUSTRALIA_UNIVS,
  'New Zealand': NEW_ZEALAND_UNIVS,
  'Hong Kong and Singapore': HKS_UNIVS
}

const renderCountryUnivs = (country) => {
  return <div key={country + ' panel'}>
    <UnivImageContainer>
      {(UNIV_MAP[country] || []).map((univImg, idx) =>
        <div key={country + 'img' + idx} style={{height: 180, display: 'inline', verticalAlign: 'middle' }}>
          <UnivImage src={univImg} />
        </div>
      )}
    </UnivImageContainer>
  </div>
}

const UnivImageContainer = styled.div`
  width: 60%;

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 100%;
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    width: 100%;
  }
`;

const UnivImage = styled.img`
  max-width: 180px;
  max-height: 180px;
  margin-right: 20px;
  margin-left: 5px;
  margin-bottom: 20px;

  @media (max-width: ${props => props.theme.screen.md}) {
    max-width: 140px;
  }

  @media (max-width: ${props => props.theme.screen.xs}) {
    max-width: 130px;
  }
`;

const Countries = ({ children }) => {
  const [index, setIndex] = useState(0)
  return (
    <div>
      <br />
      <div style={{zIndex: 10}}>
        <CountrySelector
          selectedIndex={index}
          onChange={tabIndex => setIndex(tabIndex)}
          countries={COUNTRIES}
        />
        <br /><br />
        {renderCountryUnivs(COUNTRIES[index])}
      </div>
    </div>
  )
}

Countries.propTypes = {
};

export default Countries;
